import { Seo } from '@/components/Seo/Seo';
import { HomeView } from '@/views/Home/Home.view';
import { withPreRenderSettings } from '@/utils/hocs';
import { SeoFullData } from '@/api/types/seo.types';
import { getSeoFullData } from '@/api/helpers/seo.helpers';

interface Props {
  seoFullData: SeoFullData | null;
}

function Home({ seoFullData }: Props) {
  return (
    <>
      <Seo {...seoFullData} />
      <HomeView />
    </>
  );
}

Home.getInitialProps = withPreRenderSettings(async (context, client) => {
  const seoFullData = await getSeoFullData(client, context);

  return { seoFullData: seoFullData ?? null };
});

export default Home;
