import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { AdsChunkSize } from './AdsWithInfiniteScroll.types';
import { AdsSection } from '@/components/AdsSection/AdsSection';
import { GetAdsResponse, ServiceName } from '@/api/types/ads.types';
import { GET_ADS } from '@/api/queries/ads.queries';
import { useBanner } from '@/hooks/useBanner';
import { useSaveAdsHandlersStore } from '@/store/useSaveAdsHandlersStore';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { getSavedAdsData } from '@/components/ScrollSaver/ScrollSaver.helpers';
import { PAGE_WITH_ADS } from '@/constants/common';
import { mergeAdsResults } from '@/utils/helpers';
import { usePlatformSource } from '@/hooks/usePlatformSource';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { ROUTES } from '@/constants/routes';

export const PremiumAdsWithInfiniteScroll = () => {
  const { t } = useTranslation();
  const source = usePlatformSource();
  const setSaveLastAdsHandler = useSaveAdsHandlersStore(
    state => state.setSaveLastAdsHandler
  );

  const { premiumAdsData: savedAdsData } =
    getSavedAdsData(PAGE_WITH_ADS.HOME) || {};

  const { data, loading, fetchMore, updateQuery } = useQuery<GetAdsResponse>(
    GET_ADS(ServiceName.Premium),
    {
      variables: {
        adKind: ServiceName.Premium,
        first: AdsChunkSize.Middle,
        source,
      },
    }
  );

  useLayoutEffect(() => {
    if (!data && savedAdsData) {
      updateQuery(() => savedAdsData);
    }
  }, []);

  useEffect(() => {
    setSaveLastAdsHandler(() => {
      localStorage.setItem(
        STORAGE_KEYS.LAST_ADS_DATA,
        JSON.stringify({
          page: PAGE_WITH_ADS.HOME,
          premiumAdsData: data,
        })
      );
    });
  }, [data]);

  const ads = data?.ads?.nodes || [];
  const { endCursor, hasNextPage } = data?.ads?.pageInfo || {};

  function onLoadMore() {
    if (loading || !hasNextPage || !navigator.onLine) return;
    fetchMore({
      variables: {
        after: endCursor,
      },
      updateQuery: (prevResult, { fetchMoreResult }) =>
        mergeAdsResults(prevResult, fetchMoreResult, {
          shouldFilterDuplicates: true,
        }),
    });
  }

  const { inListBanner: bannerInList } = useBanner();
  const customPush = useCustomPush();

  return (
    <AdsSection
      className="premiumAds"
      title={t('pages.home.premium_ads')}
      ads={ads}
      adsLoading={loading}
      chunkSize={AdsChunkSize.Middle}
      onLoadMore={onLoadMore}
      bannerInList={bannerInList}
      onLatestAdsClick={() => customPush(ROUTES.ad)}
    />
  );
};
