import React from 'react';
import { Footer } from '@/components/Footer/Footer';
import { TabBar } from '@/components/TabBar/TabBar';
import { PageTopControls } from '@/components/PageTopControls/PageTopControls';
import { AndroidAppBanner } from '@/components/AndroidAppBanner/AndroidAppBanner';
import { PremiumAdsWithInfiniteScroll } from '@/components/AdsWithInfiniteScroll/PremiumAdsWithInfiniteScroll';
import { ScrollSaver } from '@/components/ScrollSaver/ScrollSaver';
import { useBanner } from '@/hooks/useBanner';
import { useBridgeState } from '@/bridge/hooks';
import { useMobileDetect } from '@/hooks/useMobileDetect';
import { useUserBookmarks } from '@/hooks/bookmarks/useUserBookmarks';
import { PAGE_WITH_ADS } from '@/constants/common';

export const HomeView = () => {
  useUserBookmarks();
  const { isAndroid } = useMobileDetect();
  const { isWebView } = useBridgeState();
  const { topBanner } = useBanner();

  return (
    <>
      <ScrollSaver page={PAGE_WITH_ADS.HOME} />

      {!isWebView && isAndroid && <AndroidAppBanner />}

      {!isWebView && topBanner}

      <PageTopControls isMainHeader hasSearch hasCategories hasDivider />

      <PremiumAdsWithInfiniteScroll />

      <Footer />

      <TabBar />
    </>
  );
};
