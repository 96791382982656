import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdCards } from '@/components/AdCards/AdCards';
import * as S from '@/components/AdsSection/AdsSection.styles';
import { AdsSectionProps } from '@/components/AdsSection/AdsSection.types';
import { Button } from '@/components/UI/Button/Button';
import { InfiniteLoader } from '@/components/InfiniteLoader/InfiniteLoader';
import { Container } from '@/components/Container/Container.styles';
import { CommonTestIds } from '@/__e2e__/testIds/common';
import { SkeletonLoader } from '@/components/SkeletonLoader/SkeletonLoader';
import { AdManagerBanner } from '@/components/Banners/AdManagerBanner';
import { BannerName } from '@/components/Banners/Banners.types';
import { useAdManagerBannerVisible } from '@/components/Banners/AdManagerBanner.hooks';

export const AdsSection: React.FC<AdsSectionProps> = ({
  title,
  hasTopMargin,
  banner,
  bannerInList,
  appearance = 'titleWithBtn',
  onShowMoreClick,
  hasBottomButton = true,
  onLoadMore,
  ads,
  adsLoading,
  chunkSize,
  showMoreText,
  listDataStat,
  hasAdManagerCard,
  className,
  showMoreIcon,
  isBannerInListAtTheEnd,
  isBannerInListAfterThreeRows,
  adCardsCustomStyles,
  onLatestAdsClick,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const isOnlyTitle = appearance === 'onlyTitle';
  const isAdsListEmpty = !ads?.length;
  const isAdManagerBannerVisible = useAdManagerBannerVisible();

  return (
    <>
      <S.Box
        $hasTopMargin={hasTopMargin}
        $appearance={appearance}
        className={className}
        data-testid={dataTestId || CommonTestIds.adsSection.section}
      >
        {title && (
          <S.TitleWrapper $appearance={appearance}>
            {isOnlyTitle ? (
              <S.TitleV2
                $variant="3"
                $appearance={appearance}
                data-testid={CommonTestIds.adsSection.title}
              >
                {adsLoading ? (
                  <SkeletonLoader width="125px" height="18px" />
                ) : (
                  title
                )}
              </S.TitleV2>
            ) : (
              <S.TitleBox $appearance={appearance}>
                <S.Title
                  $variant="2"
                  $appearance={appearance}
                  data-testid={CommonTestIds.adsSection.title}
                >
                  {title}
                </S.Title>
                {onShowMoreClick && (
                  <S.More $variant="1" onClick={onShowMoreClick}>
                    {t('common.show_all')}
                  </S.More>
                )}
                {onLatestAdsClick && (
                  <S.Latest $isUnderlined onClick={onLatestAdsClick}>
                    {t('pages.ads.latest_ads')}
                  </S.Latest>
                )}
              </S.TitleBox>
            )}
          </S.TitleWrapper>
        )}

        <Container>
          <AdCards
            ads={ads}
            isInitialLoading={adsLoading}
            chunkSize={chunkSize}
            listDataStat={listDataStat}
            hasAdManagerCard={hasAdManagerCard}
            bannerInList={bannerInList}
            isBannerInListAtTheEnd={isBannerInListAtTheEnd}
            isBannerInListAfterThreeRows={isBannerInListAfterThreeRows}
            customStyles={adCardsCustomStyles}
          />

          {isOnlyTitle && hasBottomButton && onShowMoreClick && (
            <S.MoreBottomBox $isAdsListEmpty={isAdsListEmpty}>
              {adsLoading ? (
                <SkeletonLoader borderRadius={7} height="50px" />
              ) : (
                <Button
                  data-testid={CommonTestIds.adsSection.showMoreButton}
                  appearance="secondaryWhite"
                  onClick={onShowMoreClick}
                >
                  {showMoreText ?? t('common.show_all')}
                  {showMoreIcon}
                </Button>
              )}
            </S.MoreBottomBox>
          )}
          {banner}
          {isAdManagerBannerVisible && isBannerInListAtTheEnd && (
            <AdManagerBanner bannerName={BannerName.Bottom} />
          )}
        </Container>
      </S.Box>

      {onLoadMore && <InfiniteLoader onVisit={onLoadMore} />}
    </>
  );
};
